import React from "react";

class Class extends React.Component{
    render(){
        return(
            <h1>From Class Component</h1>
        )
    }
}

export default Class