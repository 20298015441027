import React, { useState } from "react";

const Condition = () =>{
    let year = 2020;
    let text = "";
    if(year%4==0){
        text = "Leap Year"
    }else{
        text = "Not Leap Year"
    }

    const [active, setActive] = useState(false)

    return(
        <div>
<h1>{text}</h1>

{active == true? <>Logout</>  : <>Login</>}

        </div>
    )
}

export default Condition;