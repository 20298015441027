import React from "react";

const Function = (props)=> {
    const fruiteList =(
        <ul>
            <li>Apple</li>
            <li>Banana</li>
            <li>Orange</li>
            <li>Kiwi</li>
        </ul>
    )
      
    let age = 8;
    let text = ""
    if(age>=18){
        text = "Voter"
    }else{
        text = "Not Available"
    }

 
    return(
        <div>
            <h1>From Functional Component</h1>
        <h3>{10 + 20}</h3>
        <h2>{fruiteList}</h2>

        <h1>Check :  {text}</h1>

        <h2>Name is :{props.name} city : {props.city}</h2>
        </div>
    )
}

export default Function;