import logo from './logo.svg';
import './App.css';
import Class from './Class';
import Function from './Function';
import myHome from './Home';
import Condition from './Condition';
import Event from './Event';
import Usestate from './Usestate';
import Useeffect from './Useeffect';
import ContactForm from './ContactForm'
import Users from './Users';
function App() {
  return (
    <div >
     <h1>From App.js</h1>
     <Class/>
     <Function name="Mayur" city="Nashik"/>
     {/* <myHome/> */}
     <Condition/>
     <Event/>
     <Usestate/>
     <Useeffect/>
     <ContactForm/>
     <Users/>
    </div>
  );
}

export default App;
