import React from "react";

const Event =()=>{
 
    const handleClick = () =>{
    
        let x = 12, y = 34, z = x+y;
        alert(z)
     
    }
    return(
        <div>
            <button onClick={()=>alert("Hello Coder!")}>Click me</button>
            <button onClick={handleClick}>Add Two Number</button> 
           
        </div>
    )
}

export default Event;