import React,{useState} from "react";

const Usestate = ()=>{
    const [car, setCar] = useState("BMW");
    const [price, setPrice] = useState(473657);
    const [color, setColor] = useState("White")

    const [user, setUser] = useState({
        nmae : "Daivesh",
        email: "daivesh.suryawanshi@arrivalsolutions.in",
        active: true,
        phone: 1234567890
    });

    const [fruite, setFruite] = useState("Apple");
    return(
        <div>
            <h1>Car name : {car}, price :{price}, color : {color}</h1>
            <h1>Profile : name : {user.nmae} , email : {user.email}, phone: {user.phone} {user.active == true ? <div>Logout</div> : <div></div>}</h1>

            <h3>Fruite is : {fruite}</h3>
            <button onClick={()=>setFruite("Orange")}>Update Fruite</button>
        </div>
    )
}

export default Usestate;