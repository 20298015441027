import React, {useState, useEffect} from "react";
import './style.css'
const Useeffect = () =>{
    const [counter, setCounter] = useState(0);
    useEffect(()=>{
        setTimeout(()=>{
            setCounter((counter)=> counter + 1)
        },1000)
    })
    return(
        <>
        <h1 className="countertext">Timer {counter}</h1>
        </>
    )
}

export default Useeffect;