import React, { useState } from "react";

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState("")
    const [course, setCourse] = useState("")

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("Name : ", name)
        console.log('Email : ', email)
        console.log("Message : ", message)
        console.log("Course : ", course)
    }

    // style object

    const formStyle = {
        width: "50%",
        backgroundColor : "#ccc",
        margin : "0 auto",
        padding : "12px 24px"
    }

    const inputStyle = {
        width : "100%",
        boxSizing: "border-box",
        display: "inline",
        marginBottom: "8px",
        padding: "12px 2px"
    }

    return (
        <>
            <form onSubmit={handleSubmit} style={formStyle}>
                <label>Name : </label>
                <input type="text" value={name}
                    onChange={(e) => setName(e.target.value)}  style={inputStyle}/>
                <br></br>
                <label>Email : </label>
                <input type="email" value={email}
                    onChange={(e) => setEmail(e.target.value)}  style={inputStyle}/>
                <br />
                <label>Message</label>
                <textarea value={message}
                    onChange={(e) => setMessage(e.target.value)} style={inputStyle}>

                </textarea>

                <br />
                <label>Course</label>
                <select value={course} onChange={(e) => setCourse(e.target.value)} style={inputStyle}>
                    <option>Select Your course</option>
                    <option value="mern">MERN stack</option>
                    <option value="web design">Web Design</option>
                    <option value="db course">SQL MYSQL Db</option>
                    <option value="mobile app dev">React Native</option>
                </select>

                <br />

                <input type="submit" value="Submit"  style={inputStyle}></input>
            </form>
        </>
    )
}

export default ContactForm;