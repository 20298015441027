import React from "react";

class myHome extends React.Component{
    render(){
        return(
            <div>
                <h1>Home Page</h1>
            </div>
        )
    }
}

export default myHome