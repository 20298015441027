import React from "react";

const Users = () => {
    const employee = [
        {
            id: 1,
            name: "Daivesh Suryawanshi",
            email: "daivesh.suryawanshi@arrivalsolutions.in",
            deg: "Founder CEO"
        },
        {
            id: 2,
            name: "Aakanksha Gavali",
            email: "aakanksha.gavlai@arrivalsolutions.in",
            deg: "HR Manager"
        },

        {
            id: 3,
            name: "Shweta Patil",
            email: "shweta.p@arrivalsolutions.in",
            deg: "Project Manager, QA Tester"
        },
        {
            id: 4,
            name: "Manish Shinde",
            email: "manish.s@arrivalsolutions.in",
            deg: "BE"
        },
        {
            id: 5,
            name: "Mayur Chaudhari",
            email: "mayur.c@arrivalsolutions.in",
            deg: "MERN Stack Developer"
        },

        {
            id: 6,
            name: "Sanket sonwane",
            email: "sanket.s@arrivalsolutions.in",
            deg: "MERN Stack Developer"
        },
        {
            id: 7,
            name: "Abhijit Mehetre",
            email: "abhijit.m@arrivalsolutions.in",
            deg: "MERN Stack Developer"
        },
        {
            id: 8,
            name: "Savita Shewale",
            email: "savita.s@arrivalsolutions.in",
            deg: "Database Engineer"
        },
        {
            id: 9,
            name: "Vishal Sonawane",
            email: "visahl.s@arrivalsolutions.in",
            deg: "Sales Ex , Customer Suppport"
        },
        {
            id: 10,
            name: "Sneha Shinde",
            email: "shena.shinde@arrivalsolutions.in",
            deg: "Freelancer Front End Devloper"
        },
        {
            id: 11,
            name: "Priya Kadam",
            email: "priya.kadam@dstechhouse.in",
            deg: "Web Designer "
        }
       


    ]
    return (
        <div>
            {
                employee.map((val) => (
                    <ul>
                        <li style={{backgroundColor: "gray", padding: "12px", color: "red"}}> Emp Id : {val.id} , Name : {val.name} , Email : {val.email} , Deg : {val.deg}</li>
                    </ul>
                ))
            }

            {/* table data */}
            <h1>Emplloyees List</h1>
            <table>
                <thead>
                    <tr >
                        <th>Id</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Deg</th>
                    </tr>
                </thead>
                <tbody>
                    {employee.map((emp)=>(
                        <tr key={emp.id}>
                            <td>{emp.id}</td>
                            <td>{emp.name}</td>
                            <td>{emp.email}</td>
                            <td>{emp.deg}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Users;